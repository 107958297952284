import { Outlet } from "react-router-dom";
import { LandingPage } from "~/LandigPage";
import { User } from "~/Login";

const PrivateRoutes = () => {
  const user = User.use();
  return user?.emailVerified ? <Outlet /> : <LandingPage />;
};

export default PrivateRoutes;
