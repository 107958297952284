import { ReactNode, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import * as AlchemyStudio from "@stability/alchemy-studio-plugin";

interface Layout {
  children?: ReactNode;
}

export const AuthProvider: React.FC<Layout> = ({ children }) => {
  const [user, setUser] = useState<AlchemyStudio.UserInfo | null>(null);

  useEffect(() => {}, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
