import * as ReactQuery from "@tanstack/react-query";

import { Generation } from "~/Generation";
import { Plugin } from "~/Plugin";

import { Cursor } from "../Query/Cursor";

import { Button } from "./Button";
import { App } from "~/App";

export declare namespace Delete {
  export { Button };
}

export namespace Delete {
  Delete.Button = Button;

  export const use = () => {
    const deleteStableDiffusionImages = Plugin.use(
      ({ deleteStableDiffusionImages }) => deleteStableDiffusionImages,
    );

    const accessToken = App.getToken();

    const queryClient = ReactQuery.useQueryClient();
    return ReactQuery.useMutation(
      async (options?: { computeIds?: ID[]; taskId: ID }) => {
        const { computeIds, taskId } = { ...options };
        if (!deleteStableDiffusionImages || !computeIds || !taskId || !accessToken)
          return;

        const output = Generation.Image.Output.get(taskId);

        //User wants to delete specific compute
        let deleteTask: boolean = false;

        //User wants to delete task
        if (output.imageIDs.length == computeIds.length) {
          deleteTask = true;
        }

        Generation.Image.Output.remove(computeIds);

        queryClient.setQueryData(
          ["Generation.Images.use", "defaultProject", "descending"],
          (
            data:
              | ReactQuery.InfiniteData<Cursor & { data: Generation.Images }>
              | undefined,
          ) => ({
            pages:
              data?.pages.map((page) => ({
                ...page,
                data: page.data.filter((image) => !computeIds.includes(image.id ?? "")),
              })) ?? [],

            pageParams: data?.pageParams ?? [],
          }),
        );

        //Do not send computeIDs while deleting task itself
        return deleteStableDiffusionImages({
          computeIDs: deleteTask ? undefined : computeIds,
          taskId,
          accessToken,
        });
      },
    );
  };

  export const useIsEnabled = () =>
    Plugin.use(({ deleteStableDiffusionImages }) => !!deleteStableDiffusionImages);
}
