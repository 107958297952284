import { GlobalState } from "../GlobalState/index";
import * as AlchemyStudio from "@stability/alchemy-studio-plugin";

export type State = {
  accessToken: String | null;
  walletInfo: AlchemyStudio.Wallet;
  addWallet: (walletInfo: AlchemyStudio.Wallet) => void;
};

export namespace State {
  const store = GlobalState.create<State>((set) => ({
    accessToken: null,
    walletInfo: {},
    addWallet: (value) =>
      set((state) => ({
        walletInfo: { ...state.walletInfo, ...value },
      })),
  }));

  export const get = store.getState;
  export const use = store;
}
