import { GlobalState } from "../GlobalState/index";
import * as AlchemyStudio from "@stability/alchemy-studio-plugin";

export type State = {
  userInfo: AlchemyStudio.UserInfo;
  addUser: (userInfo: AlchemyStudio.UserInfo) => void;
};

export namespace State {
  const store = GlobalState.create<State>((set) => ({
    userInfo: { showProfileMenu: false },

    addUser: (userInfos) =>
      set((state) => ({
        userInfo: { ...state.userInfo, ...userInfos },
      })),
  }));

  export const get = store.getState;
  export const use = store;
}
