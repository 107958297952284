import { App } from "~/App";
import { Generation } from "~/Generation";
import { Plugin } from "~/Plugin";
import * as AlchemyStudio from "@stability/alchemy-studio-plugin";

// required to spy on the response
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

export type Execute = (args?: Execute.Args) => Promise<Execute.Result>;
export namespace Execute {
  export type Args = { queryKey?: any };
  export type Result = { data?: Generation.Images };

  export const use = (_options?: Generation.Images.Options) => {
    //    const initialParams = _options
    const accessToken = App.useToken();

    const getStableDiffusionExistingImages = Plugin.use(
      ({ getStableDiffusionExistingImages }) => getStableDiffusionExistingImages,
    );

    return useMemo((): Execute | undefined => {
      if (!getStableDiffusionExistingImages) return;

      return async (_args) => {
        const pageParams = _args?.queryKey?.[1];
        const response = await getStableDiffusionExistingImages({
          ...pageParams,
          accessToken,
        });

        const userTaskList = response as AlchemyStudio.UserTaskList;
        if (!userTaskList || !userTaskList.paginationData)
          return { forward: false, limit: 8, data: [] };

        const newInputs: Generation.Image.Inputs = {};
        const newImages: Generation.Images = [];
        const newOutputs: Generation.Image.Outputs = [];
        const newGroups = (userTaskList as AlchemyStudio.UserTaskList).tasks;
        if (!newGroups) return { forward: false, limit: 8, data: [] };
        for (const group of newGroups) {
          let inputID: ID = ID.create();
          for (const image of group.images ?? []) {
            const input: Generation.Image.Input = {
              ...Generation.Image.Input.initial(ID.create()),
              ...(image.input && {
                prompts: image.input.prompts as Generation.Image.Prompts,
                model: image.input.model,
                height: image.input.height,
                width: image.input.width,
                seed: image.input.seed,
                steps: image.input.steps,
                strength: image.input.initialImage?.weight ?? 1,
                cfgScale: image.input.cfgScale,
                extras: {
                  $IPC: {
                    preset: image.input.style,
                  },
                },
              }),
            };
            newInputs[input.id] = input;
            inputID = input.id;

            newImages.push({
              id: image.id,
              inputID: group.id,
              created: image.createdAt,
              finishReason: 0,
              //src: image.blob ? URL.createObjectURL(image.blob) : undefined,
              src: image.thumbnailUrl,
              actualBlobDownloadUrl: image.imageUrl,
              thumbNailDownloadUrl: image.thumbnailUrl,
              outputID: group.id,
            });
          }

          newOutputs.push({
            count: group.images?.length ?? 0,
            id: group.id,
            imageIDs: group.images?.map((image) => image.id) ?? [],
            inputID: inputID,
            completedAt: group.images?.find((image) => image.createdAt)?.createdAt,
          });
        }

        //Sort it by creation date for detailed view navigation
        Generation.Images.add(
          newImages.sort(function (a, b) {
            if (a.created && b.created)
              return b.created.valueOf() - a.created.valueOf();

            if (a.created) return -1;
            if (b.created) return 1;

            return 0;
          }),
        );

        Generation.Image.Inputs.set((previous) => ({
          ...previous,
          ...newInputs,
        }));

        //Collection of images for history view
        Generation.Image.Output.clearAll();
        Generation.Image.Outputs.set(newOutputs);
        Generation.Image.Outputs.setPaginationData(userTaskList.paginationData);
        return {
          data: newImages,
        };
      };
    }, []);
  };
}
