import { Rainbow } from "./Rainbow";
import { Spinner } from "./Spinner";
import { Custom } from "./Custom";

export declare namespace Loading {
  export { Spinner, Rainbow, Custom };
}

export namespace Loading {
  Loading.Spinner = Spinner;
  Loading.Rainbow = Rainbow;
  Loading.Custom = Custom;
}
