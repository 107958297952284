import { useLocation } from "react-router-dom";
import { Generation } from "~/Generation";
import { User } from "~/Login";
import { Theme } from "~/Theme";
import { useClickAway } from "react-use";
import { Router } from "~/Router";
import { Client } from "~/Remote/Client";
import { Wallet } from "~/Wallet";
import { Verification } from "~/Verification";
import { App } from "~/App";
import { Plugin } from "~/Plugin";
import { UIMessage } from "~/UIMessage";
import { DiscordAuth } from "~/Login/DiscordAuth";

function NavigationButton({ isGenerate }: { isGenerate: boolean }) {
  const navigate = Router.useNavigate();
  //Verfication status from app state
  const status = Verification.use();
  const { getVerificationStatus } = Plugin.get();
  const token = App.getToken();
  const isMobileDevice = Theme.useIsMobileDevice();
  const [loading, setLoading] = useState(false);
  const [pollingTime, setPollingTime] = useState<number>(0);
  const delay = 15000; //15 sec
  const timeOut = 20 * 60000; //20 mins

  //Polling function for verification
  const pollForIdpStatus = async () => {
    if (!getVerificationStatus) throw new Error(UIMessage.ERR_PLUGIN_NOT_FOUND);
    if (!token) throw new Error(UIMessage.MSG_NO_TOKEN);
    //Check verificatin status with IDP
    const status = await getVerificationStatus({ accessToken: token });
    //Stop polling if status is null
    if (!status) return;
    const currentIdpStatus = Verification.Idp.getIdpStatusFromString(status);
    Verification.setStatus({ status: currentIdpStatus });
    //Continue polling if status is pending and until timeout
    if (currentIdpStatus == Verification.IdpStatus.PENDING && pollingTime < timeOut) {
      setPollingTime(pollingTime + delay);
    } else {
      setLoading(false);
    }
  };

  //Click handler for verification
  const onClickVerify = () => {
    setLoading(true);
    Verification.openIdpTab(token);
    const intervalId = setInterval(() => {
      pollForIdpStatus();
      if (status != Verification.IdpStatus.PENDING || pollingTime >= timeOut) {
        clearInterval(intervalId);
      }
    }, delay);
  };

  if (isGenerate) {
    return (
      <>
        <Theme.Button
          className="relative h-10 p-4"
          iconLeft={
            <Theme.Icon.CheckCircle className="relative h-4 w-4 shrink-0 overflow-hidden" />
          }
          label="Vote on images, get paid"
          labelPlacement="bottom"
          color="ternary"
          onClick={() => navigate("/validate")}
        >
          Human Validation
        </Theme.Button>
      </>
    );
  }
  return (
    <>
      <Theme.Button
        className="relative h-10 p-4"
        iconLeft={<Theme.Icon.Edit className="h-4 w-4" />}
        color="ternary"
        label="Generate image"
        labelPlacement="bottom"
        onClick={() => navigate("/generate")}
      >
        Image Generation
      </Theme.Button>
      {status != Verification.IdpStatus.SUCCESS && (
        <Theme.Button
          className={classes("relative h-10 p-4")}
          iconLeft={<Theme.Icon.ScanFaceIcon className="h-4 w-4" />}
          color={
            status == Verification.IdpStatus.FAILURE
              ? "red"
              : status == Verification.IdpStatus.PENDING
              ? "yellow"
              : "brand"
          }
          label={Verification.getToolTip(status)}
          labelPlacement="bottom"
          loading={loading}
          onClick={onClickVerify}
        >
          {!isMobileDevice && Verification.getLabel(status)}
        </Theme.Button>
      )}
    </>
  );
}

export const Right = () => {
  const navigate = Router.useNavigate();
  const { signOut } = Plugin.get();
  const path = useLocation().pathname;
  const isGenerate = useMemo(() => path.startsWith("/generate"), [path]);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const tabRef = useRef<HTMLDivElement>(null);
  useClickAway(tabRef, () => setShowProfileMenu(!showProfileMenu));
  const isMobileDevice = Theme.useIsMobileDevice();

  const logOut = async () => {
    if (!signOut) throw Error(UIMessage.ERR_PLUGIN_NOT_FOUND);
    User.remove();
    localStorage.removeItem("access_token_expiry");
    Generation.Image.Output.clearAll();
    Generation.Image.Inputs.set({});
    //TODO:clear whole store
    Wallet.add({ address: "", mnemonic: "" });
    Verification.setStatus({ status: Verification.IdpStatus.NONE });
    Client.get().clear();
    //Firebase signout
    await signOut();
    navigate("/login");
  };

  const menu = useMemo(() => {
    return (
      <>
        {showProfileMenu && (
          <div
            className={classes(
              "bg-brand-100 absolute -bottom-[80px] z-[100] flex min-w-[100px] shrink flex-col items-center",
              isGenerate && "-bottom-[40px]",
            )}
            ref={tabRef}
          >
            {!isGenerate && (
              <Theme.Button
                color="ternary"
                fullWidth
                className={classes(
                  "hover:font-semibold hover:underline",
                  !isMobileDevice && "p-2",
                )}
                onClick={() => navigate("/wallet")}
              >
                Wallet
              </Theme.Button>
            )}
            <Theme.Button
              color="ternary"
              fullWidth
              className={classes(
                "hover:font-semibold hover:underline",
                !isMobileDevice && "p-2",
              )}
              onClick={() => DiscordAuth.launchOAuthFlow()}
            >
              Link discord
            </Theme.Button>
            <Theme.Button
              color="ternary"
              fullWidth
              className={classes(
                "hover:font-semibold hover:underline",
                !isMobileDevice && "p-2",
              )}
              onClick={logOut}
            >
              Logout
            </Theme.Button>
          </div>
        )}
      </>
    );
  }, [path, showProfileMenu]);

  return (
    <div className="flex flex-row items-center justify-start gap-4 self-stretch p-1 text-sm">
      <NavigationButton isGenerate={isGenerate} />
      <div
        className="border-brand-200 hover:bg-brand-25 box-border flex flex-col items-center gap-3 rounded-lg border border-solid p-2 text-sm"
        onClick={() => {
          setShowProfileMenu(!showProfileMenu);
        }}
      >
        {Verification.IdpStatus.SUCCESS == Verification.use() ? (
          <Theme.Tooltip placement="right" content="Verified">
            <Theme.Icon.UserCheck className="cursor-pointer" size={20} />
          </Theme.Tooltip>
        ) : (
          <Theme.Tooltip placement="right" content="Not verified">
            <Theme.Icon.User className="cursor-pointer" size={20} />
          </Theme.Tooltip>
        )}

        {menu}
      </div>
    </div>
  );
};
