import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Plugin } from "~/Plugin";
import { Theme } from "~/Theme";
import { UIMessage } from "~/UIMessage";
import { Generation } from "~/Generation";
import { User } from "~/Login";
import { GlobalConstants } from "@stability/alchemy-studio-plugin";

// LoadingSpinner component
const LoadingSpinner: React.FC = () => (
  <div className="flex flex-col gap-8">
    <div className="animate-text from-brand-1100 to-brand-1100 bg-gradient-to-r via-fuchsia-300 bg-clip-text text-2xl text-transparent">
      Authenticating with Discord...
    </div>
    <Theme.Loading.Spinner className="z-[2] h-20 w-20 self-center" />
  </div>
);

// useDiscordAuth hook
const useDiscordAuth = (
  code: string | null,
  navigate: ReturnType<typeof useNavigate>,
) => {
  const { linkDiscordAccount, loginWithDiscordAccount } = Plugin.get();
  const showErrorSnackbar = Generation.Image.Exception.Snackbar.showMessage();

  const discordOAuthCallback = async () => {
    if (!linkDiscordAccount || !loginWithDiscordAccount) {
      throw new Error(UIMessage.ERR_PLUGIN_NOT_FOUND);
    }
    if (!code) {
      throw new Error(UIMessage.ERR_DISCORD_AUTHCODE_FAILED);
    }

    const user = localStorage.getItem(GlobalConstants.USER_INFO);
    if (user) {
      await linkDiscordAccount({ code });
      showErrorSnackbar(UIMessage.MSG_DISCORD_SUCESS, "center", "top", "success");
      navigate("/");
    } else {
      const response = await loginWithDiscordAccount({ code });
      if (response) {
        User.add({ ...response });
        if (response.emailVerified) {
          navigate("/generate");
        }
      }
    }
    return { data: {} };
  };

  const onError = (error: {
    message: string;
    description: string;
    statusCode: string | number;
  }) => {
    if (error.message) {
      showErrorSnackbar(error.message, "center", "top", "error");
    }
    navigate("/");
  };

  return useQuery({
    queryKey: ["Discord.LinkAccount"],
    queryFn: discordOAuthCallback,
    onError,
    staleTime: 1000,
    cacheTime: 1000,
  });
};

// Main DiscordAuth component
export function DiscordAuth() {
  const location = useLocation();
  const navigate = useNavigate();
  const code = new URLSearchParams(location.search).get("code");

  const { isLoading } = useDiscordAuth(code, navigate);

  return (
    <div className="bg-brand-50 flex h-screen w-screen flex-col items-center justify-center">
      {isLoading && <LoadingSpinner />}
    </div>
  );
}

// DiscordAuth namespace
export namespace DiscordAuth {
  const discordOauthUrl = import.meta.env.VITE_DISCORD_OAUTH_URL;
  export const launchOAuthFlow = () => {
    window.location.href = discordOauthUrl;
  };
}
