import { Generation } from "~/Generation";
import { Theme } from "~/Theme";
import { Input } from "~/Theme/Input";

import { Examples } from "./Examples";

import { Random } from "./Random";
import { Reuse } from "./Reuse";
import { Sidebar } from "./Sidebar";
import { Shuffle } from "./Shuffle";

export * from "./Prompts";

export type Prompt = {
  text?: string;
  weight: number;
};

type Props = StyleableWithChildren & {
  id: ID;
  index?: number;
  advanced?: boolean;
  readOnly?: boolean;
  variant?: "simple" | "advanced" | "display";
  allowUseAgain?: boolean;
  advancedMode: boolean;
};

export function Prompt({
  id,
  index = 0,
  variant = "simple",
  allowUseAgain,
  className,
  advancedMode,
}: Props) {
  const { deletePrompt, shuffle, setInput, input } = Generation.Image.Input.use(id);

  const promptText = input?.prompts[index]?.text;
  const promptWeight = input?.prompts[index]?.weight ?? 1;
  const isRandomPrompt = useMemo(
    () => Generation.Image.Prompt.Random.is(promptText),
    [promptText],
  );
  const [expanded, setExpanded] = useState(promptWeight >= 0);

  const onChange = useCallback(
    (prompt: string) =>
      setInput((input) => {
        (input.prompts[index] as Prompt).text = prompt;
      }),
    [setInput, index],
  );

  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const end = event.target.value.length;
      event.target.setSelectionRange(isRandomPrompt ? 0 : end, end);
    },
    [isRandomPrompt],
  );

  const shufflePrompt = useMemo(
    () => (
      <Theme.Tooltip content="Shuffle prompt" delay={500}>
        <>Prompt</>
      </Theme.Tooltip>
    ),
    [shuffle, index, input?.prompts.length],
  );

  const prompt =
    variant === "display" ? (
      <div className="flex flex-row items-center gap-3 truncate">
        <p className="w-full truncate text-base">{promptText}</p>
        <Theme.Tooltip content={promptText}>
          {<Theme.Icon.Info size={20} />}
        </Theme.Tooltip>
      </div>
    ) : (
      <div className={classes("flex flex-row")}>
        <Input
          key={keys(id, index)}
          transparent
          autoSize
          size="lg"
          className="w-full px-0 py-0"
          placeholder={
            promptWeight > 0 ? "What do you want to see?" : "What do you want to avoid?"
          }
          value={promptText}
          onChange={onChange}
          onFocus={onFocus}
        />
      </div>
    );

  const controls = useMemo(() => {
    const removeIcon = (
      <Theme.Button
        transparent
        icon={Theme.Icon.Trash}
        onClick={() => deletePrompt(index)}
        className="p-0 hover:text-red-500"
      />
    );

    return (
      <div className="flex flex-row items-center">
        {input?.prompts.length === 1 ? (
          <>{promptWeight >= 0 && shufflePrompt}</>
        ) : (
          <>
            {promptWeight >= 0 && shufflePrompt}
            {variant === "advanced" && index > 1 && removeIcon}
          </>
        )}
      </div>
    );
  }, [
    input?.prompts.length,
    promptWeight,
    shufflePrompt,
    variant,
    deletePrompt,
    index,
  ]);

  //UIChanges: Commenting negative prompt
  if (!input || (!advancedMode && promptWeight < 0)) return null;
  return variant === "display" ? (
    <div
      className={classes("flex items-center justify-between gap-2 text-lg", className)}
    >
      {prompt}
    </div>
  ) : (
    <div
      className={classes(
        "bg-brand-50 group flex w-full shrink grow gap-1 rounded px-4 pb-4 pt-2",
        className,
      )}
    >
      <div className="flex w-full flex-col gap-2">
        {promptWeight > 0 ? (
          shufflePrompt
        ) : (
          <div className="group relative flex h-fit w-fit cursor-pointer select-none flex-row items-center justify-center gap-1 whitespace-nowrap rounded border-zinc-800 bg-transparent p-0 align-middle text-base font-light opacity-100 shadow-none shadow-black/5 duration-150 hover:bg-transparent dark:border-zinc-700 dark:bg-transparent dark:text-white/80 dark:shadow-none dark:hover:bg-transparent dark:hover:text-white">
            Negative prompt
          </div>
        )}
        {prompt}
      </div>
    </div>
  );
}

export declare namespace Prompt {
  export { Random, Reuse, Sidebar, Examples, Shuffle };
}

export namespace Prompt {
  Prompt.Random = Random;
  Prompt.Reuse = Reuse;
  Prompt.Sidebar = Sidebar;
  Prompt.Examples = Examples;
  Prompt.Shuffle = Shuffle;
}
