import { Login, User } from "~/Login";
import { Signup } from "~/Signup";
import { ForgotPassword } from "~/ForgotPassword";
import { App } from "~/App";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import { DiscordAuth } from "~/Login/DiscordAuth";

export function AuthRouter() {
  const user = User.use();

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route element={<App />} path="*" />
      </Route>
      <Route
        element={user.emailVerified ? <Navigate to="/" /> : <Login />}
        path="/login"
      />
      <Route
        element={user.emailVerified ? <Navigate to="/" /> : <Signup />}
        path="/signup"
      />
      <Route element={<ForgotPassword />} path="/forgotpassword" />
      <Route element={<DiscordAuth />} path="/discord/authorize" />
      {/* <Route element={<Login />} path="/login" /> */}
    </Routes>
  );
}
